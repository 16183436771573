<template>
  <div>
    <PortfolioSlider />
    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <div class="space"></div>
      <v-container style="padding: 0 60px !important;">
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <p>
              En el ámbito privado venimos desarrollando intensamente obras de
              Arquitectura con Proyectos propios y de terceros, para comitentes
              particulares tales como edificios para Oficinas, Edificios de
              Viviendas en Propiedad Horizontal, Viviendas unifamiliares,
              Instalaciones deportivas y desarrollos habitacionales semiurbanos.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <div class="space"></div>
      <Portfolios />
    </div>
  </div>
</template>
<script>
import PortfolioSlider from '../components/slider/PortfolioPrivadaSlider'
import Portfolios from '../components/portfolio/ArqPrivada.vue'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      serviceImg: require('../assets/images/bg/paralax/bg.svg'),
      tabContent: []
    }
  },
  components: {
    PortfolioSlider,
    Portfolios
  },
  computed: {
    ...mapGetters('arqprivadas', ['arqprivadas'])
  }
}
</script>

<style scoped>
.space {
  height: 100px;
}
</style>
