<template>
  <div class="rn-brand-area brand-separation" v-if="logos.length > 0" style="background: none;">
    <v-container>
      <v-row>
        <v-col cols="12" class="mt-10">
          <div class="section-title text-center">
            <h3 class="fontWeight500 theme-color">Confían en nosotros</h3>
          </div>
          <ul class="brand-style-2">
            <li v-for="(brand, i) in logos" :key="i">
              <a :href="brand.url">
                <img :src="brand.logoImg.url" />
              </a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("logos", ["logos"]),
  },
};
</script>
