<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      width="60vw"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img :src="lightLogo" alt="Creative Agency Logo" />
        </div>
        <v-spacer></v-spacer>
        <v-btn large icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
      >
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->

          <v-list-group
            v-for="item in blocksSidebarDropDownItems"
            :key="item.title"
            v-model="item.active"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              link
              :ripple="false"
              v-for="child in item.items"
              :to="child.to"
              :key="child.title"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-for="item in items2"
            :key="item.title"
            :to="item.to"
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
          <v-list-group
            v-for="item in contactSidebarDropDownItems"
            :key="item.title"
            v-model="item.active"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              link
              :ripple="false"
              v-for="child in item.items"
              :to="child.to"
              :key="child.title"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- End sidebar block dropdown item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <!-- Menu -->

    <v-app-bar
      flat
      fixed
      class="header-one-page-nav"
      color="transparent"
      v-scroll="onScroll"
    >
      <router-link to="/" class="logo">
        <img :src="isScrolled ? lightLogo : darkLogo" width="200" />
      </router-link>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-0 pa-0 hidden-md-and-up"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <v-toolbar-items class="hidden-xs-only hidden-sm-only">
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
        >
          <v-btn
            :ripple="false"
            text
            to="/#about"
            style="color: #fff"
            link
            class="scrollactive-item"
            >Nosotros</v-btn
          >

          <v-menu
            open-on-hover
            bottom
            min-width="240"
            offset-y
            transition="scroll-y-reverse-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                text
                v-bind="attrs"
                v-on="on"
                style="color: #fff"
                to="#"
              >
                Arquitectura
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                v-for="(item, index) in portfolioDropDownItems"
                :key="index"
                :to="`${item.to}`"
                @click="setPortfoliosByName(item.title)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn :ripple="false" text to="/desarrollos" style="color: #fff"
            >Desarrollos</v-btn
          >
          <v-btn
            :ripple="false"
            text
            to="/alquiler-maquinas"
            style="color: #fff"
            >Servicios</v-btn
          >
          <v-menu
            open-on-hover
            bottom
            min-width="240"
            offset-y
            transition="scroll-y-reverse-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                text
                v-bind="attrs"
                v-on="on"
                style="color: #fff"
                to="/contact"
              >
                Contacto
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                v-for="(item, index) in contactDropDownItems"
                :key="index"
                :to="`${item.to}`"
                @click="setPortfoliosByName(item.title)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!--         <v-btn :ripple="false" text to="/contact" style="color: #fff"
          >Contacto</v-btn
        > -->
        </scrollactive>
      </v-toolbar-items>
      <!--       <v-toolbar-items class="hidden-xs-only hidden-sm-only">
        <v-btn
          :ripple="false"
          text
          v-for="(item, i) in menus"
          :to="item.link"
          :key="i"
          style="color: #fff;"
          >{{ item.name }}</v-btn
        >
      </v-toolbar-items> -->
    </v-app-bar>
    <!-- Menu -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import feather from 'feather-icons'
export default {
  data: () => ({
    portfolioDropDownItems: [
      { title: 'Arq. Publica', to: '/arq-publica' },
      { title: 'Arq. Privada', to: '/arq-privada' }
    ],
    contactDropDownItems: [
      { title: 'Contacto', to: '/contact' },
      { title: 'RRHH', to: '/rrhh' }
    ],
    drawer: false,
    icon: 'menu',
    closeIcon: 'x',
    isScrolled: false,
    drawer: false,
    items: [
      { title: 'Inicio', to: '/' },
      { title: 'Nosotros', to: '/#about' }
    ],
    items2: [
      { title: 'Desarrollos', to: '/desarrollos' },
      { title: 'Servicios', to: '/alquiler-maquinas' }
    ],
    blocksSidebarDropDownItems: [
      {
        items: [
          { title: 'Arq. Publica', to: '/arq-publica' },
          { title: 'Arq. Privada', to: '/arq-privada' }
        ],
        title: 'Arquitectura'
      }
    ],
    contactSidebarDropDownItems: [
      {
        items: [
          { title: 'Contacto', to: '/contact' },
          { title: 'RRHH', to: '/rrhh' }
        ],
        title: 'Contacto'
      }
    ],

    icon: 'menu',
    closeIcon: 'x'
  }),
  methods: {
    setPortfoliosByName() {
      this.$router.go(0)
    },

    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.isScrolled = top > 60
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    }
  },
  computed: {
    /*     ...mapGetters("menus", ["menus"]), */
    ...mapGetters('settings', ['settings']),
    lightLogo() {
      return this.settings[0].lightLogo.url
    },
    darkLogo() {
      return this.settings[0].darkLogo.url
    }
  }
}
</script>

<style lang="scss">
.feather-menu {
  color: #c6c9d8;
}
.v-app-bar--is-scrolled a {
  color: #000 !important;
}
</style>
