<template>
  <div>
    <div class="rn-gallery-area rn-section-gap position-relative">
      <div class="rn-masonary-wrapper">
        <div class="wrapper plr--70 plr_sm--30 plr_md--30">
          <div class="be-custom-gallery">
            <div
              class="gallery-wrapper gallery-grid mesonry-list grid-metro3"
              id="animated-thumbnials"
            >
              <div
                v-for="(item, i) in arqpublicas"
                :key="i"
                @click="index = i"
                class="image masonry_item portfolio-33-33"
              >
                <div class="item-portfolio-static">
                  <div class="portfolio-static">
                    <div class="thumbnail-inner">
                      <div class="thumbnail">
                        <a :href="`/arq-publica-details/${item.slug}`">
                          <v-img
                            :src="item.portfolioimages[0].url"
                            :lazy-src="item.portfolioimages[0].url"
                            height="500px"
                            width="auto"
                            alt="portfolio image"
                          />
                        </a>
                      </div>
                    </div>

                    <div class="content">
                      <div class="inner">
                        <p :href="`/arq-publica-details/${item.slug}`">
                          {{ item.category }}
                        </p>
                        <a :href="`/arq-publica-details/${item.slug}`">
                          <h4>
                            {{ item.name }}
                          </h4>
                        </a>
                        <div class="portfolio-button">
                          <router-link
                            class="rn-btn"
                            :to="`/arq-publica-details/${item.slug}`"
                            >Ver detalles</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      arqpublicas: 'arqpublicas/arqpublicas'
    })
  },
  data() {
    return {}
  }
}
</script>
