<template>
  <div>
    <div
      v-if="portfolio"
      class="rn-page-title-area pt--120 pb--190 bg_image"
      :style="{ backgroundImage: `url(${portfolio.portfolioimages[0].url})` }"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient">
                {{ portfolio.name }}
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <div class="rn-portfolio-details ptb--120">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="inner">
                  <h2>Descripción del proyecto</h2>
                  <p class="subtitle"></p>
                  <p>
                    {{ portfolio.description }}
                  </p>
                </div>

                <div class="portfolio-thumb-inner">
                  <div
                  v-if="portfolio.videoURL"
                    class="thumb position-relative mb--30 "
                    style="text-align: center"
                  >
                    <div class="thumbnail position-relative">
                      <iframe
                        width="100%"
                        height="500px"
                        :src="portfolio.videoURL"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>

                  <div
                    class="thumb mb--30"
                    v-for="(image,
                    imageIndex) in portfolio.portfolioimages.slice(1)"
                    :key="imageIndex"
                  >
                    <img width="100%" :src="image.url" alt="Portfolio Images" />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div
        class="portfolio-related-work pb--120"
        v-if="relatedProjects.length > 0"
      >
        <v-container>
          <v-row>
            <v-col>
              <div class="section-title text-center">
                <span class="theme-color font--18 fontWeight600"
                  >Más proyectos</span
                >
                <h2>Otros proyectos</h2>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt--10">
            <v-col
              lg="6"
              md="6"
              cols="12"
              v-for="(project, i) in computedArray"
              :key="i"
            >
              <div class="related-work text-center mt--30">
                <div class="thumb">
                  <a :href="project.slug">
                    <img
                      :src="project.portfolioimages[0].url"
                      alt="Portfolio-images"
                    />
                  </a>
                </div>
                <div class="inner">
                  <h4>
                    <a :href="project.slug">{{ project.name }}</a>
                  </h4>
                  <span class="category">Desarrollos</span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import feather from 'feather-icons'

export default {
  components: {},
  data() {
    return {
      serviceImg: require('../../assets/images/bg/paralax/bg.svg'),
      index: null,
      items: [
        {
          thumb: '',
          src: ''
        }
      ],
      limit: 4,
      relatedProjects: [],
      portfolio: '',
      email: '',
      address: '',
      phone: '',
      about: '',
      companyImg: ''
    }
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    },
    initialize() {
      let me = this
      axios
        .get('desarrollos/desarrollo', { params: this.$route.params.slug })
        .then(function(response) {
          console.log(response.data)
          me.portfolio = response.data
          me.items = [
            {
              thumb: response.data.videoBackgroundImage.url,
              src: response.data.video.url
            }
          ]
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    getMoreProjects() {
      let me = this
      axios
        .post('desarrollos/relatedprojects', { slug: this.$route.params.slug })
        .then(function(response) {
          me.relatedProjects = response.data
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    getSettings() {
      let me = this
      axios
        .get('settings')
        .then(function(response) {
          me.email = response.data[0].companyEmail
          me.address = response.data[0].companyAddress
          me.phone = response.data[0].companyPhone
          me.about = response.data[0].aboutInfo
          me.companyImg = response.data[0].companyImg.imageURL
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  },
  computed: {
    website() {
      return window.location.href
    },
    computedArray() {
      return this.limit
        ? this.relatedProjects.slice(0, this.limit)
        : this.relatedProjects
    }
  },
  created() {
    window.scrollTo(0, 0)
    this.initialize()
    this.getSettings()
    this.getMoreProjects()
  }
}
</script>

<style scoped>
.related-work .thumb a img {
  width: 100%;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
  height: 400px;
}
</style>
