<template>
  <div>
    <!-- Start Service Area  bg_color--1 -->
    <div
      class="rn-service-area rn-section-gap  text-center"
      id="service"
      style="padding: 25px 0px 100px 0px;"
    >
      <v-container>
        <!--         <v-row>
          <v-col lg="12">
            <div class="feature-area">
              <h2 class="theme-color">Servicios</h2>
              <h3
                class="heading-title mt--20 fontWeight500 lineheight--1-8 mb-2"
              >
                Tenemos como objetivo brindar calidad, confianza y respaldo a
                nuestros clientes, haciendo que los proyecots se correspondan
                con sus requirimientos.
              </h3>
              
              <div class="services-button">
                <router-link class="rn-btn" :to="`/portfolio-details/`"
                  >VER MÁS</router-link
                >
              </div>
            </div>
          </v-col>
        </v-row> -->
        <ServiceSix class="interior-services" />
      </v-container>
    </div>
    <!-- Start Service Area  -->
  </div>
</template>

<script>
import ServiceSix from './ServiceSix.vue'
export default {
  components: { ServiceSix }
}
</script>
