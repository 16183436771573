import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollactive from 'vue-scrollactive'
//import VueSocialSharing from "vue-social-sharing";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/main.scss'
import VideoBackground from 'vue-responsive-video-background-player'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollactive)
//Vue.use(VueSocialSharing);
Vue.component('video-background', VideoBackground)
Vue.use(Vuelidate)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC-I74drOLtlV1KkqTON1t9GexpiP92vQA',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

const { NODE_ENV } = process.env

const connectionString =
  NODE_ENV === 'development' ? 'http://localhost:4000/api' : '/api'

axios.defaults.baseURL = connectionString

new Vue({
  router,
  store,
  vuetify,
  render: function(h) {
    return h(App)
  }
}).$mount('#app')
