<template>
  <div>
    <div class="rn-content-box-area rn-content-box-style--1 grey-bg" id="about">
      <v-row class="row--0" align="center">
        <v-col lg="6" md="12" cols="12" style="padding: 0px; ">
          <div
            v-if="settings[0]"
            class="thumbnail"
            style="height: 700px; background-position: 50% 50%; background-repeat: no-repeat; background-size: cover; object-fit: cover; "
            :style="{
              backgroundImage: `url(${aboutImage})`
            }"
          ></div>
        </v-col>

        <v-col md="12" lg="6" cols="12" class="mt_lg--50 mt_md--30 mt_sm--30">
          <div class="content pt-10 pb-15">
            <h2
              class="fontWeight500 theme-color"
              style="font-size: 40px; !important"
            >
              Nosotros
            </h2>
            <div class="aboutUsText" v-html="about" />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliderSettings: {
        useTransform: false,
        useCSS: false,
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 0
      }
    }
  },
  computed: {
    ...mapGetters('portfolios', ['portfolios']),
    ...mapGetters('settings', ['settings']),
    about() {
      return this.settings[0].aboutInfo
    },
    aboutImage() {
      return this.settings[0].companyImg.imageURL
    },
    aboutImg() {
      return this.settings[0]
    }
  }
}
</script>

<style lang="scss">
.grey-bg {
  background: #404041;
}
.v-application .aboutUsText p {
  color: #fff !important;
}
</style>
