<template>
  <div>
    <div
      v-if="alquilersliders.length > 0"
      class="rn-page-title-area pt--120 pb--190 bg_image"
      :style="{ backgroundImage: `url(${alquilersliders[0].sliderImg.url})` }"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient">
                {{ alquilersliders[0].title }}
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <div class="rn-portfolio-details ptb--120">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="inner">
                  <h2>Descripción del servicio</h2>
                  <p class="subtitle"></p>
                  <p>
                    {{ maquinas[0].description }}
                  </p>
                </div>
                <div class="portfolio-thumb-inner">
                  <div
                    class="thumb mb--30"
                    v-for="(image, imageIndex) in maquinasImages"
                    :key="imageIndex"
                  >
                    <img
                      width="100%"
                      :src="image.logoImg.url"
                      alt="Portfolio Images"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import feather from 'feather-icons'

export default {
  components: {},
  data() {
    return {
      alquilersliders: [],
      maquinasImages: [],
      serviceImg: require('../assets/images/bg/paralax/bg.svg'),
      index: null,
      /*       items: [
        {
          thumb: '',
          src: ''
        }
      ], */
      limit: 4,
      maquinas: '',
      email: '',
      address: '',
      phone: '',
      about: '',
      companyImg: ''
    }
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    },
    initialize() {
      let me = this
      axios
        .get('maquinas', { params: this.$route.params.slug })
        .then(function(response) {
          console.log(response.data)
          me.maquinas = response.data
          /*           me.items = [
            {
              thumb: response.data.videoBackgroundImage.url,
              src: response.data.video.url
            }
          ] */
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    getSettings() {
      let me = this
      axios
        .get('settings')
        .then(function(response) {
          me.email = response.data[0].companyEmail
          me.address = response.data[0].companyAddress
          me.phone = response.data[0].companyPhone
          me.about = response.data[0].aboutInfo
          me.companyImg = response.data[0].companyImg.imageURL
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    getMaquinas() {
      let me = this
      axios
        .get('maquinas')
        .then(function(response) {
          me.maquinas = response.data
          console.log(me.maquinas)
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    getMaquinasImages() {
      let me = this
      axios
        .get('maquinasImagenes')
        .then(function(response) {
          me.maquinasImages = response.data
          console.log(me.maquinas)
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    getalquilersliders() {
      let me = this
      axios
        .get('alquilersliders')
        .then(function(response) {
          me.alquilersliders = response.data
          console.log(me.alquilersliders)
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  },
  computed: {
    website() {
      return window.location.href
    }
  },
  created() {
    window.scrollTo(0, 0)
    this.initialize()
    this.getSettings()
    this.getMaquinas()
    this.getalquilersliders()
    this.getMaquinasImages()
  }
}
</script>

<style scoped>
.related-work .thumb a img {
  width: 100%;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
  height: 400px;
}
</style>
