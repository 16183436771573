<template>
  <div
    class="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
    data-black-overlay="5"
    :style="{ backgroundImage: 'url(' + bg_image_url + ')' }"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="rn-page-title text-center pt--100">
            <h2 class="heading-title theme-gradient" style="font-size: 50px; font-weight: 400;">
              {{ title }}
            </h2>
            <p>
              {{ subtitle }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    bg_image_url: {
      type: String,
    },
  },
};
</script>
