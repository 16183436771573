<template>
  <div>
<!--     <div class="slider-wrapper">
      <VueSlickCarousel
        v-if="sliders"
        v-bind="settings"
        class="rn-slider-area slider-activation rn-slick-dot dot-light mb--0"
      >
        <div
          class="
          slide slide-style-2
          align-center
          justify-center
          fullscreen
          bg_image
        "
          data-black-overlay="5"
          v-for="slider in sliders"
          :key="slider._id"
          :style="{ backgroundImage: 'url(' + slider.sliderImg.url + ')' }"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="inner text-center">
                  <h2
                    class="heading-title theme-gradient"
                    style="font-size: 50px !important; font-weight: 400;"
                  >
                    {{ slider.title }}
                  </h2>
                  <p class="description">
                    {{ slider.subtitle }}
                  </p>
                  <div
                    class="slide-btn"
                    v-if="slider.buttonText && slider.buttonURL"
                  >
                    <a
                      class="rn-button-style--2 btn-primary-color"
                      :href="slider.buttonURL"
                      >{{ slider.buttonText }}</a
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>

      </VueSlickCarousel>
    </div> -->
    <div
      v-if="sliders"
      class="rn-page-title-area pt--120 pb--190 bg_image"
      :style="{ backgroundImage: `url(${sliders[0].sliderImg.url})` }"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient">
                {{ sliders[0].title }}
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import axios from 'axios'

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliders: [
        {
          title: '',
          subtitle: ''
        }
      ],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 20
      }
    }
  },
  methods: {
    getSliders() {
      let me = this
      axios
        .get('arq-publica-slider')
        .then(function(response) {
          me.sliders = response.data
          console.log(me.sliders)
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  },
  created() {
    this.getSliders()
  }
}
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
</style>
