import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout'
import Home from '../views/Home'
import About from '../views/About'
import ArqPublicaDetails from '../views/portfolio/ArqPublicaDetails'
import ArqPrivadaDetails from '../views/portfolio/ArqPrivadaDetails'
import PortfolioDetailsDesarrollos from '../views/portfolio/PortfolioDetailsDesarrollos'
import arqPrivada from '../views/ArqPrivada'
import arqPublica from '../views/ArqPublica'
import AlquilerMaquinas from '../views/AlquilerMaquinas'

import PortfoliosDesarrollos from '../views/PortfoliosDesarrollos'
import Contact from '../views/Contact'
import RRHH from '../views/RRHH'
import ErrorPath from '../views/404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    name: 'Layout',
    children: [
      {
        path: '/',
        component: Home,
        name: 'Home'
      },
      {
        path: '/about',
        component: About,
        name: 'About'
      },
      {
        path: '/arq-privada-details/:slug',
        component: ArqPrivadaDetails,
        name: 'ArqPrivadaDetails'
      },
      {
        path: '/arq-publica-details/:slug',
        component: ArqPublicaDetails,
        name: 'ArqPublicaDetails'
      },
      {
        path: '/desarrollos-details/:slug',
        component: PortfolioDetailsDesarrollos,
        name: 'PortfolioDetailsDesarrollos'
      },
      {
        path: '/alquiler-maquinas',
        component: AlquilerMaquinas,
        name: 'AlquilerMaquinas'
      },
      {
        path: '/arq-privada',
        component: arqPrivada,
        name: 'arqPrivada'
      },
      {
        path: '/arq-publica',
        component: arqPublica,
        name: 'arqPublica'
      },
      {
        path: '/desarrollos',
        component: PortfoliosDesarrollos,
        name: 'PortfoliosDesarrollos'
      },
      {
        path: '/contact',
        component: Contact,
        name: 'Contact'
      },
      {
        path: '/RRHH',
        component: RRHH,
        name: 'RRHH'
      },

      {
        path: '*',
        component: ErrorPath
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
