<template>
  <div class="rn-contact-area rn-section-gap" style="padding: 120px 0;">
    <div class="contact-form--1">
      <v-container>
        <v-row align="start" class="row--35">
          <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
            <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
              <h2 class="heading-title">Contacto:</h2>
              <p class="description">
                Contanos lo que necesitas y nos ponemos en contacto.
              </p>
            </div>
            <div class="form-wrapper">
              <form @submit.prevent>
                <div
                  v-if="submitted && !$v.name.required"
                  class="invalid-feedback"
                >
                  El nombre es requerido.
                </div>
                <div
                  v-if="submitted && !$v.name.minLength"
                  class="invalid-feedback"
                >
                  El nombre debe contenter al menos 3 letras.
                </div>

                <label>
                  <input
                    type="text"
                    v-model="name"
                    placeholder="Nombre *"
                    :class="{
                      'is-invalid': submitted && $v.name.$error
                    }"
                  />
                </label>
                <div
                  v-if="submitted && $v.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.email.required">El email es requerido.</span>
                  <span v-if="!$v.email.email"
                    >Por favor ingresa un email válido.</span
                  >
                </div>
                <label>
                  <input
                    type="text"
                    v-model="email"
                    placeholder="Email *"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  />
                </label>

                <label>
                  <input type="text" v-model="subject" placeholder="Asunto" />
                </label>
                <label>
                  <textarea
                    v-model="message"
                    placeholder="¿Cómo podemos ayudarte?"
                  ></textarea>
                </label>
                <button
                  class="rn-button-style--2 btn_solid"
                  @click="submitForm"
                >
                  Enviar
                </button>
              </form>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
            <div class="thumbnail mb_md--40 mb_sm--40">
              <img
                src="../../assets/images/contacto/nosotros_home.jpg"
                alt="trydo"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from '../../repositories/Repository'
import Swal from 'sweetalert2'
import { required, email, minLength } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      submitted: false
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3)
    },
    email: {
      required,
      email
    },
    message: {
      required
    }
  },
  methods: {
    submitForm() {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      let me = this
      const formData = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      }

      axios
        .post('/contact', formData)
        .then(function(response) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Email enviado',
            showConfirmButton: false,
            timer: 3000,
            width: '300px'
          })
          console.log(response)
          me.cleanForm()
        })
        .catch(function(error) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Error',
            showConfirmButton: false,
            timer: 3000,
            width: '300px'
          })
          console.log(error)
        })
    },
    cleanForm() {
      this.submitted = false
      this.name = ''
      this.email = ''
      this.subject = ''
      this.message = ''
    }
  }
}
</script>

<style scoped>
.invalid-feedback {
  color: red;
}
.is-invalid {
  border-color: red;
}
</style>
