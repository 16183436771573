<template>
  <div v-if="settings[0].whatsapp.text">
    <a :href="whatsappLink" class="whatsapp" target="_blank">
      <v-icon color="#fff" large class="whatsapp-icon">mdi-whatsapp</v-icon></a
    >
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("settings", ["settings"]),
    whatsappLink() {
      return (
        "https://wa.me/" +
        this.settings[0].whatsapp.phone +
        "?text=" +
        this.settings[0].whatsapp.text
      );
    },
  },
};
</script>
<style scoped lang="scss">
.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 5px;
}
</style>
