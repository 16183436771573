<template>
  <div>
    <Breadcrump
      :title="title"
      :subtitle="subtitle"
      :bg_image_url="bg_image_url"
    />
    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <AboutUs />
      <TeamThree />
      <Brand />
      <ContactAddresses />
    </div>
  </div>
</template>

<script>
import Breadcrump from "../components/shared/Breadcrump";
import AboutUs from "../components/about/AboutFour";
import TeamThree from "../components/team/TeamThree";
import Brand from "../components/brand/Brand";
import ContactAddresses from "../components/contact/ContactAddresses";
export default {
  components: {
    Breadcrump,
    AboutUs,
    TeamThree,
    Brand,
    ContactAddresses,
  },
  data() {
    return {
      serviceImg: require("../assets/images/bg/paralax/bg.svg"),
      title: `La mejor solución para cada necesidad`,
      subtitle: "",
      bg_image_url:
        "https://res.cloudinary.com/degq0ap4m/image/upload/v1647284605/lwsxb1tvdkbmrh11iwai.jpg",
    };
  },
};
</script>
