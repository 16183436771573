<template>
  <div>
    <Sliders />
    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <Services />
      <AboutUs id="about" />
      <Brand />
      <OurProjects
        :latitude="13.7013266"
        :longitude="-89.226622"
        :title="'Titulo Marcador'"
      />
      <ContactAddresses />
    </div>
  </div>
</template>

<script>
import Sliders from '../components/slider/SliderFive'
import Services from '../components/service/Services'
import Brand from '../components/brand/Brand'
import AboutUs from '../components/about/AboutUs'
import OurProjects from '../components/shared/OurProjects'
import ContactAddresses from '../components/contact/ContactAddresses'

export default {
  data() {
    return {
      serviceImg: require('../assets/images/bg/paralax/bg.svg')
    }
  },
  components: {
    Sliders,
    Services,
    AboutUs,
    OurProjects,
    Brand,
    ContactAddresses
  }
}
</script>
