<template>
  <v-container class="rn-finding-us-area">
    <v-row>
      <v-col cols="12" class="mt-10">
        <div class="section-title text-center">
          <h3 class="fontWeight500 theme-color">Servicios</h3>
        </div>
      </v-col>
      <!-- Start Single Team Area  -->
      <v-col
        lg="4"
        md="4"
        sm="6"
        cols="6"
        v-for="team in teamContent"
        :key="team.id"
      >
        <div class="team">
          <div class="thumbnail">
            <img :src="team.src" alt="Team Images" />
          </div>
<!--           <div class="content">
            <h4 class="title">{{ team.title }}</h4>
            <p class="designation">{{ team.designation }}</p>
          </div> -->
          <!-- <SocialIcon /> -->
        </div>
      </v-col>
      <!-- End Single Team Area  -->
    </v-row>
  </v-container>
</template>

<script>
import SocialIcon from "../../components/social/Social";
export default {
  components: {
    SocialIcon,
  },
  data() {
    return {
      teamContent: [
        {
          id: 1,
          src: require("../../assets/images/team/team-01.jpg"),
          title: "Jone Due",
          designation: "Sr. Web Developer",
        },
        {
          id: 2,
          src: require("../../assets/images/team/team-02.jpg"),
          title: "Fatima-Asrafi",
          designation: "Front-end Engineer",
        },
        {
          id: 3,
          src: require("../../assets/images/team/team-03.jpg"),
          title: "Al-Amin Bali",
          designation: "Sr. Graphic Designer",
        },
      ],
    };
  },
};
</script>
