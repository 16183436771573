<template>
  <div>
    <div class="section-title text-center mb-15">
      <h3 class="fontWeight500 theme-color">Nuestras Obras</h3>
    </div>
    <gmap-map
      :center="center"
      :zoom="13"
      style="width: 100%; height: 800px"
      ref="mapRef"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        :content="infoContent"
        @closeclick="infoWinOpen = false"
      >
      </gmap-info-window>

      <gmap-marker
        :key="i"
        v-for="(m, i) in markers2"
        :position="m.position"
        :clickable="true"
        @click="toggleInfoWindow(m, i)"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      markers: [],
      markers2: [],
      center: {
        lat: -32.94800952448933,
        lng: -60.684038248004065
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoContent: null,
      infoOptions: {
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        },
        content:
          '<div id="content">' +
          '<div id="siteNotice">' +
          '</div>' +
          '<h1 id="firstHeading" class="firstHeading">Uluru</h1>' +
          '<div id="bodyContent">' +
          '<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large ' +
          'sandstone rock formation in the southern part of the ' +
          'Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) ' +
          'south west of the nearest large town, Alice Springs; 450&#160;km ' +
          '(280&#160;mi) by road. Kata Tjuta and Uluru are the two major ' +
          'features of the Uluru - Kata Tjuta National Park. Uluru is ' +
          'sacred to the Pitjantjatjara and Yankunytjatjara, the ' +
          'Aboriginal people of the area. It has many springs, waterholes, ' +
          'rock caves and ancient paintings. Uluru is listed as a World ' +
          'Heritage Site.</p>' +
          '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
          'https://en.wikipedia.org/w/index.php?title=Uluru</a> ' +
          '(last visited June 22, 2009).</p>' +
          '</div>' +
          '</div>'
      }

      /*       markers: [
        {
          position: {
            lat: 47.376332,
            lng: 8.547511
          },
          infoText:
            '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h1 id="firstHeading" style="font-size: 10px !important" class="firstHeading">Uluru</h1>' +
            '<div id="bodyContent">' +
            '<p><b>Uluru1</b>, also referred to as <b>Ayers Rock</b>, is a large ' +
            'sandstone rock formation in the southern part of the ' +
            'Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) ' +
            'south west of the nearest large town, Alice Springs; 450&#160;km ' +
            '(280&#160;mi) by road. Kata Tjuta and Uluru are the two major ' +
            'features of the Uluru - Kata Tjuta National Park. Uluru is ' +
            'sacred to the Pitjantjatjara and Yankunytjatjara, the ' +
            'Aboriginal people of the area. It has many springs, waterholes, ' +
            'rock caves and ancient paintings. Uluru is listed as a World ' +
            'Heritage Site.</p>' +
            '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
            'https://en.wikipedia.org/w/index.php?title=Uluru</a> ' +
            '(last visited June 22, 2009).</p>' +
            '</div>' +
            '</div>'
        },
        {
          position: {
            lat: 47.374592,
            lng: 8.548867
          },
          infoText:
            `<div style="height: 200px; width: 200px; background-image: url('https://res.cloudinary.com/degq0ap4m/image/upload/v1655654444/6-800x445_bmlmas.jpg')" id="content">` +
            '<div id="siteNotice">' +
            '</div>' +
            '<div id="bodyContent">' +
            '<a><b>Proyecto Nombre</b></a>' +
            '</div>' +
            '</div>'
        },
        {
          position: {
            lat: 47.379592,
            lng: 8.549867
          },
          infoText: '<strong>Marker 3</strong>'
        }
      ] */
    }
  },

  methods: {
    getMarkers() {
      let me = this
      axios
        .get('arq-publica')
        .then(function(response) {
          response.data.map((item) => {
            me.markers2.push({
              position: {
                lat: item.marker.position.lat,
                lng: item.marker.position.lng
              },
              infoText: `
            <a href="/arq-publica-details/${item.slug}" style="color: #fff;">
            <div style="height: 200px; width: 200px; background-image: url(${item.portfolioimages[0].url}); background-position: 50% 50%; background-repeat: no-repeat; background-size: cover; object-fit: cover;" id="content">
            <div id="bodyContent" style="    position: absolute;
    bottom: 0;
    background: #af9e80;
    width: 87%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 12px;">
            <b>${item.name}</b>
            </div>
            </div></a>`
            })
          })
        })
        .catch(function(error) {
          console.log(error)
        })

      axios
        .get('arq-privada')
        .then(function(response) {
          response.data.map((item) => {
            console.log(response.data)
            me.markers2.push({
              position: {
                lat: item.marker.position.lat,
                lng: item.marker.position.lng
              },
              infoText: `
            <a href="/arq-privada-details/${item.slug}" style="color: #fff;">
            <div style="height: 200px; width: 200px; background-image: url(${item.portfolioimages[0].url}); background-position: 50% 50%; background-repeat: no-repeat; background-size: cover; object-fit: cover;" id="content">
            <div id="bodyContent" style="    position: absolute;
    bottom: 0;
    background: #af9e80;
    width: 87%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 12px;">
            <b>${item.name}</b>
            </div>
            </div></a>`
            })
          })
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText
      /*       this.$attrs.title = 'hola'
      console.log('sisi')
      let style2 = document.querySelectorAll('gm-style-iw-d')
      console.log(style2) */
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  },
  created() {
    this.getMarkers()
  },
  mounted() {
    this.$refs.mapRef.addListener('click', () => {
      console.log('acaaaaaaaaaaa')
      this.infoWinOpen = false
    })
  }
}
</script>

<style scoped>
.holaClass {
  font-size: 5px !important;
}
</style>
