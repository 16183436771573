import Vue from 'vue'
import Vuex from 'vuex'
import Repository from '../repositories/Repository'

Vue.use(Vuex)

const sliders = {
  namespaced: true,
  state: {
    sliders: [
      {
        title: '',
        subtitle: '',
        sliderImg: {
          public_id: '',
          url: ''
        },
        buttonText: '',
        buttonURL: ''
      }
    ]
  },
  mutations: {
    setSliders(state, payload) {
      state.sliders = payload
    }
  },
  actions: {
    async getSliders({ commit }, payload) {
      const response = await Repository.get(`/sliders`, {
        headers: { token: this.state.token }
      })
      commit('setSliders', response.data)
    }
  },
  getters: {
    sliders: (state) => {
      return state.sliders
    }
  }
}

const settings = {
  namespaced: true,
  state: {
    settings: [
      {
        videobackground: {
          public_id: '',
          url: ''
        },
        darkLogo: {
          url: ''
        },
        lightLogo: {
          url: ''
        }
      }
    ]
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload
    }
  },
  actions: {
    async getSettings({ commit }, payload) {
      const response = await Repository.get(`/settings`, {
        headers: { token: this.state.token }
      })
      commit('setSettings', response.data)
    }
  },
  getters: {
    settings: (state) => {
      return state.settings
    }
  }
}

const logos = {
  namespaced: true,
  state: { logos: [] },
  mutations: {
    setLogos(state, payload) {
      state.logos = payload
    }
  },
  actions: {
    async getLogos({ commit }, payload) {
      const response = await Repository.get(`/logos`, {
        headers: { token: this.state.token }
      })
      commit('setLogos', response.data)
    }
  },
  getters: {
    logos: (state) => {
      return state.logos
    }
  }
}

const arqprivadas = {
  namespaced: true,
  state: { arqprivadas: [] },
  mutations: {
    setArqPrivada(state, payload) {
      state.arqprivadas = payload
    }
  },
  actions: {
    async getArqPrivada({ commit }, payload) {
      const response = await Repository.get(`/arq-privada`)
      console.log(response.data)
      commit('setArqPrivada', response.data)
    }
  },
  getters: {
    arqprivadas: (state) => {
      return state.arqprivadas
    }
  }
}

const arqpublicas = {
  namespaced: true,
  state: { arqpublicas: [] },
  mutations: {
    setArqPublica(state, payload) {
      state.arqpublicas = payload
    }
  },
  actions: {
    async getArqPublica({ commit }, payload) {
      const response = await Repository.get(`/arq-publica`)
      commit('setArqPublica', response.data)
    }
  },
  getters: {
    arqpublicas: (state) => {
      return state.arqpublicas
    }
  }
}

const desarrollos = {
  namespaced: true,
  state: { desarrollos: [] },
  mutations: {
    setDesarrollos(state, payload) {
      state.desarrollos = payload
    }
  },
  actions: {
    async getDesarrollos({ commit }, payload) {
      const response = await Repository.get(`/desarrollos`, {
        headers: { token: this.state.token }
      })
      commit('setDesarrollos', response.data)
    }
  },
  getters: {
    desarrollos: (state) => {
      return state.desarrollos
    }
  }
}

export default new Vuex.Store({
  state: {
    snackbar: { showing: false, text: '', color: '' },
    loadingOverlay: false
  },
  mutations: {
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar
    },
    setLoadingOverlay(state, loadingOverlay) {
      state.loadingOverlay = loadingOverlay
    },
    removeLoadingOverlay(state, loadingOverlay) {
      state.loadingOverlay = loadingOverlay
    }
  },
  actions: {
    setSnackbar({ commit }, snackbar) {
      snackbar.showing = true
      snackbar.color = snackbar.color || 'success'
      commit('setSnackbar', snackbar)
    },
    setLoadingOverlay({ commit }, loadingOverlay) {
      loadingOverlay = true
      commit('setLoadingOverlay', loadingOverlay)
    },
    removeLoadingOverlay({ commit }, loadingOverlay) {
      loadingOverlay = false
      commit('removeLoadingOverlay', loadingOverlay)
    }
  },
  modules: {
    settings: settings,
    sliders: sliders,
    logos: logos,
    arqprivadas: arqprivadas,
    arqpublicas: arqpublicas,
    desarrollos: desarrollos
  }
})
