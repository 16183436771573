<template>
  <div>
    <RRHHSlider />
<!--     <Breadcrump
      :title="title"
      :subtitle="subtitle"
      :bg_image_url="bg_image_url"
    /> -->
    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <RRHH />
    </div>
  </div>
</template>

<script>
import Breadcrump from "../components/shared/Breadcrump";
import RRHH from "../components/contact/RRHH";
import RRHHSlider from '../components/slider/RRHHSlider.vue'
export default {
  components: { Breadcrump, RRHH, RRHHSlider },
  data() {
    return {
      serviceImg: require("../assets/images/bg/paralax/bg.svg"),
      title: "RRHH",
      subtitle: "Contact Us",
      bg_image_url:
        "https://res.cloudinary.com/degq0ap4m/image/upload/v1647284605/lwsxb1tvdkbmrh11iwai.jpg",
    };
  },
};
</script>
