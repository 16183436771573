<template>
  <div>
    <!-- Start Finding us Area bg_color--1  -->
    <div class="rn-finding-us-area rn-finding-us">
      <div class="inner">
        <div class="content-wrapper">
          <div class="content">
            <h4 class="theme-color">Nosotros</h4>
            <p>
              Tenemos como objetivo brindar calidad, confianza y respaldo a
              nuestros clientes, haciendo que los proyectos se correspondan con
              sus requerimientos.
            </p>
          </div>
        </div>
        <div class="thumbnail">
          <div class="image">
            <img
              src="../../assets/images/about/finding-us-01.png"
              alt="Finding Images"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End Finding us Area  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("settings", ["settings"]),
    about() {
      return this.settings[0].aboutInfo;
    },
    companyImg() {
      return this.settings[0].companyImg.imageURL;
    },
  },
};
</script>

<style lang="scss">
.section-title p {
  padding: 0 !important;
  font-weight: 200 !important;
}
</style>
