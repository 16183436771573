<template>
  <div>
    <PortfolioSlider />
    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <div class="space"></div>
      <v-container style="padding: 0 60px !important;">
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <p>
              Hemos realizado distintas obras dentro del ámbito de la Obra
              Pública, tales como instalaciones deportivas, instalaciones
              hospitalarias, plazas y paseos urbanos, edificios educativos, etc.
              ; siendo nuestros comitentes la Municipalidad de Rosario,
              Fundación Hospital Provincial, Hospital de Niños Victor J. Vilela,
              Universidad Nacional de Rosario.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <div class="space"></div>
      <Portfolios />
    </div>
  </div>
</template>
<script>
import PortfolioSlider from '../components/slider/PortfolioPublicaSlider.vue'
import Portfolios from '../components/portfolio/ArqPublica.vue'
export default {
  data() {
    return {
      serviceImg: require('../assets/images/bg/paralax/bg.svg'),
      tabContent: []
    }
  },
  components: {
    PortfolioSlider,
    Portfolios
  }
}
</script>

<style scoped>
.space {
  height: 100px;
}
</style>
