<template>
  <div>
    <DesarrollosSlider />
    <div
      class="rn-section-gap parallax bg_image"
      :style="{ backgroundImage: 'url(' + serviceImg + ')' }"
    >
      <div class="space"></div>
      <v-container style="padding: 0 60px !important;">
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <p>
              Contamos con profesionales, personal y equipamiento con los que
              estamos desarrollando emprendimientos de viviendas en Propiedad
              Horizontal y Barrios Privados en el área de Rosario y Funes.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <Desarrollos
        :portfolios="desarrollos"
        :tabItems="desarrolloCategories"
        :tabContent="tabContent"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DesarrollosSlider from '../components/slider/DesarrollosSlider.vue'
import Desarrollos from '../components/desarrollos/Desarrollos.vue'
export default {
  data() {
    return {
      desarrolloCategories: [{ id: 'Desarrollos', name: 'Desarrollos' }],
      serviceImg: require('../assets/images/bg/paralax/bg.svg'),
      tabContent: []
    }
  },
  components: {
    DesarrollosSlider,
    Desarrollos
  },
  methods: {
    getInfo() {
      this.getTabContent()
    },
    getTabContent() {
      this.desarrollos.forEach((item, i) => {
        this.desarrollos[i].category = item.category.name
      })

      function groupBy(key) {
        return function group(array) {
          return array.reduce((acc, obj) => {
            const property = obj[key]
            acc[property] = acc[property] || []
            acc[property].push(obj)
            return acc
          }, {})
        }
      }
      const groupByCategory = groupBy('category')
      const projectsSortedByCategory = groupByCategory(this.desarrollos)

      for (const groupByCategory in projectsSortedByCategory) {
        this.tabContent.push({
          id: groupByCategory,
          name: groupByCategory,
          content: projectsSortedByCategory[groupByCategory]
        })
      }
    }
  },
  computed: {
    ...mapGetters('desarrollos', ['desarrollos'])
  },
  async created() {
    await this.$store.dispatch(
      'desarrollos/getDesarrollos',

      {
        root: true
      }
    )
    this.getInfo()
  }
}
</script>

<style scoped>
.space {
  height: 100px;
}
</style>
