<template>
  <v-row class="service-one-wrapper mt--30">
    <!-- Start Single Service  -->
    <v-col
      xl="4"
      lg="4"
      md="4"
      sm="4"
      cols="12"
      v-for="(item, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--1 pl-10 pr-10">
        <div class="content">
          <h2
            class="heading-title theme-color"
            style="font-weight: 500 !important; font-size: 33px !important"
          >
            {{ item.title }}
          </h2>
          <p>
            {{ item.desc }}
          </p>
        </div>
      </div>
      <div class="services-button">
        <router-link class="rn-btn" :to="item.to"
          >VER MÁS</router-link
        >
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      serviceContent: [
        {
          src: require('../../assets/images/icons/icon-01.png'),
          title: 'Arquitectura pública',
          desc: `El área de Arquitectura pública ejecuta obras de gran mgnitud para
clientes de carácter público.`,
          to: '/arq-publica'
        },
        {
          src: require('../../assets/images/icons/icon-02.png'),
          title: 'Arquitectura privada',
          desc: `El área de Arquitectura privada desarrolla obras de distintas escalas
para comitentes particulares.`,
          to: '/arq-privada'
        },
        {
          src: require('../../assets/images/icons/icon-02.png'),
          title: 'Desarrollos',
          desc: `El área de Desarrollos proyecta, desarrolla y comercializa proyectos de
carácter privado.`,
          to: '/desarrollos'
        }
      ]
    }
  }
}
</script>
