<template>
  <div v-if="sliderSettings">
    <VueSlickCarousel
      v-if="sliders.length > 0 && sliderSettings[0]"
      v-bind="settings"
      class="slider-digital-agency slider-activation rn-slick-dot dot-light mb--0 color-white"
    >
      <div
        class="slide slide-style-2 fullscreen d-flex align-center bg_image"
        :data-black-overlay="sliderSettings[0].sliderOverlayLevel"
        v-for="(slider, i) in sliders"
        :key="i"
        :style="{ backgroundImage: 'url(' + slider.sliderImg.url + ')' }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner text-center">
                <h1 class="heading-title theme-gradient">
                  {{ slider.title }}
                </h1>
                <p class="description">
                  {{ slider.subtitle }}
                </p>
                <div
                  class="slide-btn"
                  v-if="slider.buttonText && slider.buttonURL"
                >
                  <a
                    class="rn-button-style--2 btn-primary-color"
                    :href="slider.buttonURL"
                    >{{ slider.buttonText }}</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import axios from 'axios'
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliders: [],
      sliderSettings: [],
      sliderContent: [
        {
          src: require('../../assets/images/bg/bg-image-21.jpg'),
          title: 'Development',
          desc: ` There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration.`
        },
        {
          src: require('../../assets/images/bg/bg-image-20.jpg'),
          title: 'Marketing.',
          desc: ` There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration.`
        },
        {
          src: require('../../assets/images/bg/bg-image-23.jpg'),
          title: 'Agency',
          desc: ` There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration.`
        },
        {
          src: require('../../assets/images/bg/bg-image-22.jpg'),
          title: 'Freelancing',
          desc: ` There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration.`
        }
      ],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 20
      }
    }
  },
  methods: {
    initialize() {
      let me = this
      axios
        .get('/sliders')
        .then(function(response) {
          me.sliders = response.data
          console.log(me.sliders)
        })
        .catch(function(error) {
          console.log(error)
        })
      axios
        .get('/settings')
        .then(function(response) {
          me.sliderSettings = response.data
          console.log(me.sliders)
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  },
  computed: {
    //...mapGetters('sliders', ['sliders'])
    // ...mapGetters('settings', ['settings'])
  },
  created() {
    this.initialize()
  }
}
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
</style>
